import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as SectionCharacters } from '../Assets/characters.svg';
import "./characters.css";
import Footer from '../Sections/footer';

function setImageHref(imageId, url) {
    const imageElement = document.getElementById(imageId);
    if (imageElement) {
        imageElement.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", url);
    } else {
        console.error(`Element with id ${imageId} not found.`);
    }
}

function insertBioIntoSvg(textContent, maxWidthVW) {
    const textElement = document.getElementById("c_bio").children[0];
    if (!textElement) {
        console.error('Element with id "bio" not found.');
        return;
    }

    // Create a temporary SVG element to measure text
    const tempSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const tempText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    tempSvg.appendChild(tempText);
    document.body.appendChild(tempSvg);

    // Set attributes for measurement
    tempText.setAttribute('x', '0');
    tempText.setAttribute('y', '0');
    tempText.setAttribute('font-size', '16'); // Set font size as needed

    // Split text into lines based on viewport width
    const maxWidthPx = window.innerWidth * maxWidthVW / 100; // Max width in pixels
    let lines = [];
    let currentLine = '';
    let tempTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');

    textContent.split(' ').forEach(word => {
        tempTspan.textContent = currentLine + word + ' ';
        tempText.appendChild(tempTspan);
        const width = tempTspan.getBBox().width;

        if (width > maxWidthPx) {
            lines.push(currentLine.trim());
            currentLine = word + ' ';
            tempTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
        } else {
            currentLine += word + ' ';
        }
    });

    // Push the last line
    lines.push(currentLine.trim());

    // Clean up temporary elements
    document.body.removeChild(tempSvg);

    // Clear existing content and append new tspans
    textElement.innerHTML = '';
    lines.forEach((line, index) => {
        const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
        tspan.setAttribute('x', '0'); // Center text horizontally within the <text> element
        if (index > 0) {
            tspan.setAttribute('dy', '1.2em');
        }
        tspan.textContent = line;
        textElement.appendChild(tspan);
    });

    // Set the x attribute of the <text> element to center text within SVG
    textElement.setAttribute('x', '50%');
}


export default function Characters({ section_id, characters }) {
    var [images, setImages] = useState([]);
    var startingIndex = 0;
    var isUseEffectcalled = false;
    const [svgContent, setSvgContent] = useState(null);

    useEffect(() => {
        console.log(characters, "characters");
        if (!isUseEffectcalled && characters) {
            isUseEffectcalled = true;
            setSvgContent(characters[startingIndex].imageBase64);               
            UpdateValue();         
        }
    }, [])
    // const fetchSvg = async (imageDetails) => {
    //     try {
    //         const response = await fetch(imageDetails.image_url);
    //         if (response.ok) {
    //             const svgText = await response.text();
    //             setSvgContent(svgText);
    //             setTimeout(() => {
    //                 document.getElementById("c_designation").children[0].innerHTML = imageDetails.designation;
    //                 document.getElementById("c_bio").children[0].innerHTML = "";
    //                 insertBioIntoSvg(imageDetails.image_description, 33);
    //                 document.getElementById("c_name").children[0].innerHTML = "";
    //                 document.getElementById("c_name").children[0].innerHTML = `<tspan class="cls-64" x="0" y="0">${imageDetails.image_name.split(" ")[0]}</tspan><tspan class="cls-65" x="0" y="74.13">${imageDetails.image_name.split(" ").filter((v, i) => i != 0).join(" ")}</tspan>`
    //             }, 0);

    //         } else {
    //             console.error('Failed to fetch SVG');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching SVG:', error);
    //     }
    // };
    // useEffect(() => {
    //     if (!isUseEffectcalled) {
    //         isUseEffectcalled = true;
    //         let requestOptions = {
    //             method: "GET",
    //             redirect: "follow"
    //         };
    //         //get image order
    //         fetch("https://thestoryloft.in/api/orders/" + section_id, requestOptions)
    //             .then((response) => response.json())
    //             .then((orderResult) => {
    //                 console.log(orderResult[0].orderList, "orderList");
    //                 fetch(`https://thestoryloft.in/api/images?section_id=${section_id}&refresh=` + Number(Math.random()).toFixed(1), requestOptions)
    //                     .then((response) => response.json())
    //                     .then(async (result) => {
    //                         images = result.map(val => {
    //                             val["image_order"] = orderResult[0].orderList.split(",").indexOf(val.image_id.toString());
    //                             if (val["image_order"] == -1) val["image_order"] = 10000;
    //                             return val;
    //                         }).sort((a, b) => a.image_order - b.image_order);
    //                         setImages(images);
    //                         console.log(images,"images")
    //                         if (images[startingIndex]) {
    //                             fetchSvg(images[startingIndex]);
    //                             console.log(images[startingIndex], "images[startingIndex]")
    //                         }
    //                     })
    //                     .catch((error) => console.error(error));
    //             })
    //             .catch((error) => console.error(error))
    //     }
    // }, []);

    // const addRectToG = () => {
    //     // Get the <g> element by ID
    //     const gElement = document.getElementById("c_next");

    //     // Get all <polygon> elements inside the <g>
    //     const polygons = gElement.querySelectorAll("polygon");

    //     if (polygons.length > 0) {
    //         // Initialize variables for bounding box
    //         let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

    //         // Iterate over all polygons to find the bounding box
    //         polygons.forEach(polygon => {
    //             const points = polygon.getAttribute("points").split(" ");
    //             points.forEach(coord => {
    //                 const [x, y] = coord.split(",").map(Number);
    //                 minX = Math.min(minX, x);
    //                 minY = Math.min(minY, y);
    //                 maxX = Math.max(maxX, x);
    //                 maxY = Math.max(maxY, y);
    //             });
    //         });

    //         // Calculate width and height from bounding box
    //         const width = maxX - minX;
    //         const height = maxY - minY;

    //         // Create a new <rect> element
    //         const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");

    //         // Set attributes for the <rect> based on the bounding box
    //         rect.setAttribute("x", minX.toString());
    //         rect.setAttribute("y", minY.toString());
    //         rect.setAttribute("width", width.toString());
    //         rect.setAttribute("height", height.toString());
    //         rect.setAttribute("fill", "transparent"); // Transparent fill to capture clicks
    //         rect.setAttribute("cursor", "pointer"); // Show pointer cursor on hover

    //         // Append the <rect> to the <g> element
    //         gElement.prepend(rect); // Add it at the start of the <g>
    //     }

    // }

    const UpdateValue = () =>{
        console.log(startingIndex,"startingIndex");
            setTimeout(() => {
                document.getElementById("c_designation").children[0].innerHTML = characters[startingIndex].designation;
                document.getElementById("c_bio").children[0].innerHTML = "";
                insertBioIntoSvg(characters[startingIndex].image_description, 33);
                document.getElementById("c_name").children[0].innerHTML = "";
                let nameSplit = characters[startingIndex].image_name.split(" ");
                if(nameSplit[1] && nameSplit[2] && nameSplit[1].length <=2 && nameSplit[2].length <=2)
                document.getElementById("c_name").children[0].innerHTML = `<tspan class="cls-64" x="0" y="0">${nameSplit.join(" ")}</tspan>`
                else
                document.getElementById("c_name").children[0].innerHTML = `<tspan class="cls-64" x="0" y="0">${nameSplit[0]}</tspan><tspan class="cls-64" x="0" y="74.13">${nameSplit.filter((v, i) => i != 0).join(" ")}</tspan>`
                // Get the <g> element by ID
                const gElement = document.getElementById("c_previous");
                // Get the element's position
                const gElementPosition = gElement.getBoundingClientRect();
                // Create a new <rect> element
                const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");

                // Set attributes for the <rect>
                rect.setAttribute("x", "390"); // Starting x-coordinate
                rect.setAttribute("y", "1095"); // Starting y-coordinate
                rect.setAttribute("width", "250"); // Width of the rectangle
                rect.setAttribute("height", "84"); // Height of the rectangle
                if ( startingIndex == 0)
                rect.setAttribute("cursor", "no-drop");
                else
                rect.setAttribute("cursor", "pointer");
                rect.setAttribute("fill", "transparent"); // Transparent fill to capture clicks

                // Append the <rect> to the <g> element
                gElement.prepend(rect); // Add it at the start of the <g>

                // Get the <g> element by ID
                const gElementNext = document.getElementById("c_next");
                // Get the element's position
                const gElementNextPosition = gElementNext.getBoundingClientRect();
                // Create a new <rect> element
                const rectNext = document.createElementNS("http://www.w3.org/2000/svg", "rect");

                // Set attributes for the <rect>
                rectNext.setAttribute("x", "1330"); // Starting x-coordinate
                rectNext.setAttribute("y", "1095"); // Starting y-coordinate
                rectNext.setAttribute("width", "322"); // Width of the rectNextangle
                rectNext.setAttribute("height", "69"); // Height of the rectNextangle
                if ( startingIndex + 1 == characters.length)
                    rectNext.setAttribute("cursor", "no-drop");
                else
                rectNext.setAttribute("cursor", "pointer");
                rectNext.setAttribute("fill", "transparent"); // Transparent fill to capture clicks

                // Append the <rect> to the <g> element
                gElementNext.prepend(rectNext); // Add it at the start of the <g>

                const leftArrow = document.getElementById('c_previous');
                const rightArrow = document.getElementById('c_next');
                console.log(startingIndex,characters,rightArrow,leftArrow, "rightArrow")
                if (leftArrow && startingIndex == 0) leftArrow.style.cursor = "no-drop";
                if (rightArrow && startingIndex + 1 == characters.length) rightArrow.style.cursor = "no-drop";
                if (leftArrow) {
                    leftArrow.addEventListener('click', () => {
                        if (startingIndex > 0) {
                            startingIndex--;
                            if (startingIndex == 0) {
                                leftArrow.style.cursor = "no-drop";
                                rightArrow.style.cursor = "pointer";
                            }
                            setSvgContent(characters[startingIndex].imageBase64); 
                            UpdateValue();
                        }
                    });
                }

                if (rightArrow) {
                    
                    rightArrow.addEventListener('click', async () => {
                        console.log("right clicked...")
                        if (startingIndex < characters.length - 1) {
                            startingIndex++;
                            if (startingIndex + 1 == characters.length) {
                                rightArrow.style.cursor = "no-drop";
                                leftArrow.style.cursor = "pointer";
                            }
                            console.log(startingIndex,characters,"characterscharacterscharacters")
                            setSvgContent(characters[startingIndex].imageBase64); 
                            UpdateValue();
                        }
                    });
                }

            }, 100)
    
    }
    // useEffect(() => {
    //     console.log("svgcontent useeffect...")
    //     if (svgContent) {
    //         setTimeout(() => {
    //                  // Get the <g> element by ID
    //             // const gElement = document.getElementById("c_next");

    //             // // Create a new <rect> element
    //             // const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");

    //             // // Set attributes for the <rect>
    //             // rect.setAttribute("x", "1331"); // Starting x-coordinate
    //             // rect.setAttribute("y", "1097"); // Starting y-coordinate
    //             // rect.setAttribute("width", "340"); // Width of the rectangle
    //             // rect.setAttribute("height", "78"); // Height of the rectangle
    //             // rect.setAttribute("fill", "transparent"); // Transparent fill to capture clicks

    //             // // Append the <rect> to the <g> element
    //             // gElement.prepend(rect); // Add it at the start of the <g>
    //             const leftArrow = document.getElementById('c_previous');
    //             const rightArrow = document.getElementById('c_next');
    //             console.log(rightArrow, "rightArrow")
    //             if (leftArrow && startingIndex == 0) leftArrow.style.cursor = "no-drop";
    //             if (rightArrow && startingIndex + 1 == images.length) rightArrow.style.cursor = "no-drop";
    //             if (leftArrow) {
    //                 leftArrow.addEventListener('click', () => {
    //                     if (startingIndex > 0) {
    //                         startingIndex--;
    //                         if (startingIndex == 0) {
    //                             leftArrow.style.cursor = "no-drop";
    //                             rightArrow.style.cursor = "pointer";
    //                         }
    //                     }
    //                 });
    //             }

    //             if (rightArrow) {
                    
    //                 rightArrow.addEventListener('click', async () => {
    //                     console.log("right clicked...")
    //                     if (startingIndex < images.length - 1) {
    //                         startingIndex++;
    //                         if (startingIndex + 1 == images.length) {
    //                             rightArrow.style.cursor = "no-drop";
    //                             leftArrow.style.cursor = "pointer";
    //                         }
    //                     }
    //                 });
    //             }

    //         }, 1000)
    //     }
    // }, [svgContent]);

    return (
        <Grid className='character-svg-container'>
            
            {svgContent && (
                <div style={{ zIndex: 10 }}
                    className='character-responsive-svg'
                    dangerouslySetInnerHTML={{ __html: svgContent }}
                />
            )}
            {svgContent && (<Footer />)}
        </Grid>
    );
}
