import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Header from './Sections/Header';
import Portfolio from './SubPages/portfolio';
import Client from './SubPages/client';
import Testimonial from './SubPages/testimonial';
import "./main.css";
import Section1 from './Sections/Section1';
import Section2 from './Sections/Section2';
import Section3 from './Sections/Section3';
import Section4 from './Sections/Section4';
import Section5 from './Sections/Section5';
import { Route, Routes, useLocation } from 'react-router-dom';
import Characters from "./SubPages/characters";
import LearningAndDevelopment from './SubPages/learningAndDevelopment';
import PerformanceAndFineArt from "./SubPages/prefAndFineArt";
import NewsAndUpdates from './SubPages/newsAndUpdates';
import ScrollToTop from './ScrollToTop';
import ClientAndTestimonials from "./SubPages/clientsAndtestimonials";
import BrandCommunications from './SubPages/brandCommunications';
import { CircularProgress } from '@mui/material'; // Import CircularProgress from MUI

const fetchSvg = async (imageDetails) => {
    try {
        const response = await fetch(imageDetails.image_url);
        if (response.ok) {
            const svgText = await response.text();
            return svgText;

        } else {
            console.error('Failed to fetch SVG');
        }
    } catch (error) {
        console.error('Error fetching SVG:', error);
    }
};

export default function Main() {
    const location = useLocation();
    const isHome = location.pathname === '/';
    const isSeperateOutline = location.pathname === '/characters';
    var [characters, setCharacters] = useState(null);
    var isUseEffectcalled = false;

    
const processImages = async (images) => {
    const characters = await Promise.all(
        images.map(async (val) => {
            val["imageBase64"] = await fetchSvg(val);
            return val;
        })
    );
    console.log(characters,"characterscharacters")
    setCharacters(characters);
};

    useEffect(() => {
        if (!isUseEffectcalled) {
            isUseEffectcalled = true;
            let requestOptions = {
                method: "GET",
                redirect: "follow"
            };
            //get image order
            fetch("https://thestoryloft.in/api/orders/28", requestOptions)
                .then((response) => response.json())
                .then((orderResult) => {
                    console.log(orderResult[0].orderList, "orderList");
                    fetch(`https://thestoryloft.in/api/images?section_id=28&refresh=` + Number(Math.random()).toFixed(1), requestOptions)
                        .then((response) => response.json())
                        .then(async (result) => {
                            let images = result.map(val => {
                                val["image_order"] = orderResult[0].orderList.split(",").indexOf(val.image_id.toString());
                                if (val["image_order"] == -1) val["image_order"] = 10000;
                                return val;
                            }).sort((a, b) => a.image_order - b.image_order);
                            processImages(images);                            
                        })
                        .catch((error) => console.error(error));
                })
                .catch((error) => console.error(error))
        }
    }, []);

    return (
        <Grid container className={`${isHome ? 'mainOutline' : isSeperateOutline ? '' : ""}`} >
            <Header />
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<React.Fragment>
                    <Section1 />
                    <Section2 />
                    <Section3 />
                    <Section4 />
                    <Section5 />
                </React.Fragment>} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/client" element={<ClientAndTestimonials />} />
                <Route path="/testimonials" element={<ClientAndTestimonials />} />
                <Route path="/characters" element={characters?<Characters section_id={28} characters={characters}/>: 
        <div className="loader-container">
          <CircularProgress />
        </div>
       } />
                <Route path="/learningAndDevelopment" element={<LearningAndDevelopment section_id={23} />} />
                <Route path="/performanceAndFineArt" element={<PerformanceAndFineArt pref_section_id={24} fineArt_section_id={25} />} />
                <Route path="/newsAndUpdate" element={<NewsAndUpdates section_id={26} />} />
                <Route path="/brandCommunications" element={<BrandCommunications section_id={30} />} />
            </Routes>
        </Grid>
    )
}