import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { ReactComponent as FooterSVG } from '../Assets/footer_new.svg';
import { useNavigate } from 'react-router-dom';

export default function Footer({marginTop}) {
    const navigate = useNavigate();

    useEffect(() => {
        const workMenu = document.getElementById('work');
        if (workMenu) {
            workMenu.addEventListener('click', () => {
                navigate('/portfolio')
            });
        }
        const clientMenu = document.getElementById('client');
        if (clientMenu) {
            clientMenu.addEventListener('click', () => {
                navigate('/client')
            });
        }

        const testimonialsMenu = document.getElementById('testimonials');
        if (testimonialsMenu) {
            testimonialsMenu.addEventListener('click', () => {
                navigate('/testimonials')
            });
        }

        const learningAndDevelopment = document.getElementById('learningAndDevelopment_footer');
        if (learningAndDevelopment) {
            learningAndDevelopment.addEventListener('click', () => {
                navigate('/learningAndDevelopment')
            });
        }

        const performances = document.getElementById('performances_footer');
        if (performances) {
            performances.addEventListener('click', () => {
                navigate('/performanceAndFineArt')
            });
        }

        const newsAndUpdate = document.getElementById('newsAndUpdate');
        if (newsAndUpdate) {
            newsAndUpdate.addEventListener('click', () => {
                navigate('/newsAndUpdate')
            });
        }

        const brandCommunications = document.getElementById('brandCommunications_footer');
        if (brandCommunications) {
            brandCommunications.addEventListener('click', () => {
                navigate('/brandCommunications')
            });
        }

        const contact = document.getElementById('contact');
        if (contact) {
            contact.addEventListener('click', () => {
                navigate('/')
            });
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (workMenu) {
                workMenu.removeEventListener('click', () => {
                });
            }
            if (clientMenu) {
                clientMenu.removeEventListener('click', () => {
                });
            }
            if (testimonialsMenu) {
                testimonialsMenu.removeEventListener('click', () => {
                });
            }
            if (learningAndDevelopment) {
                learningAndDevelopment.removeEventListener('click', () => {
                });
            }
            if (performances) {
                performances.removeEventListener('click', () => {
                });
            }
            if (newsAndUpdate) {
                newsAndUpdate.removeEventListener('click', () => {
                });
            }
            if (brandCommunications) {
                brandCommunications.removeEventListener('click', () => {
                });
            }
        };
    }, []);

    return (
        <Grid className='Footeroutline' style={{ marginTop:marginTop?marginTop: "0px" }}>
            <FooterSVG />
        </Grid>
    );
}
